import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { graphql } from "gatsby"
import BlogDetailsContent from "../components/BlogContent/BlogDetailsContent"

const Teamplate = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={post.frontmatter.title}
        homePageText="Home"
        homePageUrl="/"
        sectionPageText="Blog"
        sectionPageUrl="/blog"
        activePageText={post.frontmatter.title}
      />
      <BlogDetailsContent
        postTitle={post.frontmatter.title}
        postContent={post.html}
        postDate={post.frontmatter.date}
      />
      <Footer />
    </Layout>
  )
}

// Get Post content and metadata using graphql
export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
      }
    }
  }
`
export default Teamplate
